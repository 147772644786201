import androidx.compose.material.MaterialTheme
import androidx.compose.material.Shapes
import androidx.compose.material.Typography
import androidx.compose.material.darkColors
import androidx.compose.material.lightColors
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color

val PrimaryColor = Color(0xfff89070) // salmon
val SecondaryColor = Color(0xff3c1c5b) // dark purple
val ErrorColor = Color(0xFFF44336)
val BackgroundColor = Color(0xfff6f5f0) // cream
val SurfaceColor = Color(0xffe1c2f0) // lilac
val OnPrimaryColor = Color(0xFFFFFFFF)
val OnSecondaryColor = Color(0xFFFFFFFF)
val OnErrorColor = Color(0xFFFFFFFF)
val OnBackgroundColor = Color(0xFF000000)
val OnSurfaceColor = Color(0xFF000000)

private val LightColorPalette = lightColors(
    primary = PrimaryColor,
    primaryVariant = Color(0xFF115293),
    secondary = SecondaryColor,
    secondaryVariant = Color(0xFF9A0036),
    background = BackgroundColor,
    surface = SurfaceColor,
    error = ErrorColor,
    onPrimary = OnPrimaryColor,
    onSecondary = OnSecondaryColor,
    onBackground = OnBackgroundColor,
    onSurface = OnSurfaceColor,
    onError = OnErrorColor
)

private val DarkColorPalette = darkColors(
    primary = PrimaryColor,
    primaryVariant = Color(0xFF115293),
    secondary = SecondaryColor,
    secondaryVariant = Color(0xFF9A0036),
    background = Color(0xFF121212),
    surface = Color(0xFF121212),
    error = ErrorColor,
    onPrimary = OnPrimaryColor,
    onSecondary = OnSecondaryColor,
    onBackground = OnBackgroundColor,
    onSurface = OnSurfaceColor,
    onError = OnErrorColor
)

@Composable
fun AppTheme(
    darkTheme: Boolean = false, // You can use a parameter or a system setting to determine the theme
    content: @Composable () -> Unit
) {
    val colors = if (darkTheme) {
        DarkColorPalette
    } else {
        LightColorPalette
    }

    MaterialTheme(
        colors = colors,
        typography = Typography(),
        shapes = Shapes(),
        content = content
    )
}