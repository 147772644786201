import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.itemsIndexed
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

@Composable
fun LandingPage(id: String = "index", modifier: Modifier) {

    LazyVerticalGrid(
        modifier = modifier,
        columns = GridCells.FixedSize(800.dp),
        verticalArrangement = Arrangement.Center,
        horizontalArrangement = Arrangement.Center
    ) {
        item(span = {
            GridItemSpan(maxLineSpan)
        }
        ) {
            Carousel(modifier = modifier)
        }

        itemsIndexed(contents) { index, item ->
            HyperlinkText(
                modifier = modifier,
                linkText = item.linkText,
                fullText = item.text,
                fontSize = 40.sp,
                hyperlinks = item.link
            )
        }

    }
}

val contents = listOf(
    Contents("Get in touch", listOf("touch"), listOf("mailto:adele@adelecarpenter.com")),
    Contents(
        "Sessionize Profile",
        listOf("Sessionize Profile"),
        listOf("https://sessionize.com/adelecarpenter")
    )
)

data class Contents(
    val text: String,
    val linkText: List<String>,
    val link: List<String>
)