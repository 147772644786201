import adele.composeapp.generated.resources.Res
import adele.composeapp.generated.resources.adele_devoxx_uk_2024_1
import adele.composeapp.generated.resources.adele_devoxx_uk_2024_2
import adele.composeapp.generated.resources.adele_goto_ams_2022
import adele.composeapp.generated.resources.adele_gotoams_2023
import adele.composeapp.generated.resources.adele_ndc_london_2022
import adele.composeapp.generated.resources.adele_porto_2023
import adele.composeapp.generated.resources.adele_techorama_2024
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.interaction.collectIsDraggedAsState
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeight
import androidx.compose.foundation.layout.requiredWidthIn
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.PagerState
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import androidx.compose.ui.util.lerp
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import kotlin.math.absoluteValue

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun Carousel(
    autoScrollDuration: Long = 3000L,
    modifier: Modifier
) {
    val pagerState = rememberPagerState(pageCount = {
        images.size
    })
    val isDragged by pagerState.interactionSource.collectIsDraggedAsState()
    if (isDragged.not()) {
        with(pagerState) {
            var currentPageKey by remember { mutableStateOf(0) }
            LaunchedEffect(key1 = currentPageKey) {
                launch {
                    delay(timeMillis = autoScrollDuration)
                    val nextPage = (currentPage + 1).mod(pageCount)
                    animateScrollToPage(page = nextPage)
                    currentPageKey = nextPage
                }
            }
        }
    }
    Box(
        modifier = modifier
            .requiredHeight(600.dp)
            .requiredWidthIn(min = 400.dp, max = 1200.dp)
            .aspectRatio(ratio = 16.0f / 9.0f)
    ) {
        HorizontalPager(
            contentPadding = PaddingValues(horizontal = 32.dp),
            pageSpacing = 16.dp,
            state = pagerState,
        ) { page ->
            val painter = painterResource(images[page])
            Image(
                painter,
                contentScale = ContentScale.Crop,
                modifier = Modifier.aspectRatio(ratio = 16.0f / 9.0f)
                    .carouselTransition(page, pagerState).clip(RoundedCornerShape(10.dp)),
                contentDescription = "carousel image $page"
            )
        }
        DotIndicators(
            pageCount = images.size,
            pagerState = pagerState,
            modifier = modifier.align(Alignment.BottomCenter)//.offset(y = (-100).dp)
        )
    }
}


val images = listOf(
    Res.drawable.adele_porto_2023,
    Res.drawable.adele_gotoams_2023,
    Res.drawable.adele_devoxx_uk_2024_1,
    Res.drawable.adele_goto_ams_2022,
    Res.drawable.adele_ndc_london_2022,
    Res.drawable.adele_techorama_2024,
    Res.drawable.adele_devoxx_uk_2024_2
)

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun DotIndicators(
    pageCount: Int,
    pagerState: PagerState,
    modifier: Modifier
) {
    Row(modifier = modifier) {
        repeat(pageCount) { iteration ->
            val color = if (pagerState.currentPage == iteration) MaterialTheme.colors.secondary else MaterialTheme.colors.surface
            Box(
                modifier = Modifier.size(10.dp).padding(2.dp)
                    .clip(CircleShape)
                    .background(color)
            )
        }
    }
}

@OptIn(ExperimentalFoundationApi::class)
fun Modifier.carouselTransition(page: Int, pagerState: PagerState) =
    graphicsLayer {
        val pageOffset =
            ((pagerState.currentPage - page) + pagerState.currentPageOffsetFraction).absoluteValue

        val transformation =
            lerp(
                start = 0.9f,
                stop = 1f,
                fraction = 1f - pageOffset.coerceIn(0f, 1f)
            )
        alpha = transformation
        scaleY = transformation
    }