@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package adele.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val adele_devoxx_uk_2024_1: DrawableResource by 
      lazy { init_adele_devoxx_uk_2024_1() }

  public val adele_devoxx_uk_2024_2: DrawableResource by 
      lazy { init_adele_devoxx_uk_2024_2() }

  public val adele_goto_ams_2022: DrawableResource by 
      lazy { init_adele_goto_ams_2022() }

  public val adele_gotoams_2023: DrawableResource by 
      lazy { init_adele_gotoams_2023() }

  public val adele_ndc_london_2022: DrawableResource by 
      lazy { init_adele_ndc_london_2022() }

  public val adele_porto_2023: DrawableResource by 
      lazy { init_adele_porto_2023() }

  public val adele_techorama_2024: DrawableResource by 
      lazy { init_adele_techorama_2024() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }
}

public val Res.drawable.adele_devoxx_uk_2024_1: DrawableResource
  get() = CommonMainDrawable0.adele_devoxx_uk_2024_1

private fun init_adele_devoxx_uk_2024_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:adele_devoxx_uk_2024_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/adele.composeapp.generated.resources/drawable/adele_devoxx_uk_2024_1.jpg", -1, -1),
    )
)

public val Res.drawable.adele_devoxx_uk_2024_2: DrawableResource
  get() = CommonMainDrawable0.adele_devoxx_uk_2024_2

private fun init_adele_devoxx_uk_2024_2(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:adele_devoxx_uk_2024_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/adele.composeapp.generated.resources/drawable/adele_devoxx_uk_2024_2.jpg", -1, -1),
    )
)

public val Res.drawable.adele_goto_ams_2022: DrawableResource
  get() = CommonMainDrawable0.adele_goto_ams_2022

private fun init_adele_goto_ams_2022(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:adele_goto_ams_2022",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/adele.composeapp.generated.resources/drawable/adele_goto_ams_2022.jpg", -1, -1),
    )
)

public val Res.drawable.adele_gotoams_2023: DrawableResource
  get() = CommonMainDrawable0.adele_gotoams_2023

private fun init_adele_gotoams_2023(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:adele_gotoams_2023",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/adele.composeapp.generated.resources/drawable/adele_gotoams_2023.jpg", -1, -1),
    )
)

public val Res.drawable.adele_ndc_london_2022: DrawableResource
  get() = CommonMainDrawable0.adele_ndc_london_2022

private fun init_adele_ndc_london_2022(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:adele_ndc_london_2022",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/adele.composeapp.generated.resources/drawable/adele_ndc_london_2022.jpg", -1, -1),
    )
)

public val Res.drawable.adele_porto_2023: DrawableResource
  get() = CommonMainDrawable0.adele_porto_2023

private fun init_adele_porto_2023(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:adele_porto_2023",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/adele.composeapp.generated.resources/drawable/adele_porto_2023.jpg", -1, -1),
    )
)

public val Res.drawable.adele_techorama_2024: DrawableResource
  get() = CommonMainDrawable0.adele_techorama_2024

private fun init_adele_techorama_2024(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:adele_techorama_2024",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/adele.composeapp.generated.resources/drawable/adele_techorama_2024.jpeg", -1, -1),
    )
)

public val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/adele.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)
